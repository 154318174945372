import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	notifications: [],
}

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		enqueue: (state, { payload: notification }) => {
			state.notifications = [
				...state.notifications,
				{ key: new Date().getTime() + Math.random(), ...notification }
			];
		},
		close: (state, { payload: key }) => {
			state.notifications = state.notifications.map(notification => (
				(notification.key === key)
					? { ...notification, dismissed: true }
					: { ...notification }
			));
		},
		remove: (state, { payload: key }) => {
			state.notifications = state.notifications.filter(notification => notification.key !== key);
		},
	}
});

export const { enqueue, close, remove } = notificationsSlice.actions;

export default notificationsSlice.reducer;