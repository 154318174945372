import { createSlice } from "@reduxjs/toolkit";
import { createSagaAction } from "saga-toolkit";
import Cookies from "js-cookie";
import {
	DEFAULT_PAGE_SIZE,
	TRANSACTION_TYPES,
	TRANSACTION_SOURCES,
	TRANSACTION_STATUSES,
} from "@services/config";

import { getPeriod } from "@utils";

const name = "transactions";

export const actions = {
	FETCH: `${name}/fetchTransactions`,
	REFUND: `${name}/transactionRefund`,
	CAPTURE: `${name}/transactionCapture`,
	REVERSE: `${name}/transactionReverse`,
	DOWNLOAD: `${name}/transactionDownload`,
	DOWNLOAD_PENDING: `downloadPending`,
	DOWNLOAD_FULFILLED: `downloadFulfilled`,
	DOWNLOAD_ERROR: `downloadError`,
};

export const transactionCapture = createSagaAction(actions.CAPTURE);
export const transactionRefund = createSagaAction(actions.REFUND);
export const fetchTransactions = createSagaAction(actions.FETCH);
export const transactionReverse = createSagaAction(actions.REVERSE);

export const initialFilters = {
	journalType: [],
	statuses: [
		TRANSACTION_STATUSES.PROCESSING,
		TRANSACTION_STATUSES.ACQUIRERSUCCESS,
		TRANSACTION_STATUSES.CANCELED,
		TRANSACTION_STATUSES.ACQUIRERFAILED,
		TRANSACTION_STATUSES.PENDINGMERCHANTACTION,
		TRANSACTION_STATUSES.ACQUIRERPROCESSING,
		TRANSACTION_STATUSES.ACQUIRERTEMPORARYFAILED,
	],
	channel: [TRANSACTION_SOURCES.E_COMMERCE, TRANSACTION_SOURCES.POS],
	period: getPeriod(),
	query: "",
	lastFourCardNumber: null,
	isProduction: true,
	orderRef: null,
	currency: [],
	storeId: [],
	country: [],
	applicationId: [],
};

export const transactionsSlice = createSlice({
	name,
	initialState: {
		downloading: false,
		loading: true,
		data: [],
		error: null,
		success: null,
		pagination: {
			pageNumber: 1,
			pageSize: DEFAULT_PAGE_SIZE,
			returned: 0,
			total: 0,
			totalAvailable: 0,
		},
		filters: {
			...initialFilters,
		},
	},
	reducers: {
		// setFilters: (state, { payload }) => ({ ...state, filters: { ...state.filters, ...payload } }),
		setFilters: (state, { payload }) => ({ ...state, filters: payload }),
		clear: (state) => ({ ...state, error: null, success: null }),
		setSuccess: (state, { payload }) => ({ ...state, success: payload }),
		updateTransaction: (state, { payload }) => {
			if (payload["paymentId"]) {
				state.data = state.data.map((transaction) => {
					if (transaction.paymentId === payload.paymentId) {
						return {
							...transaction,
							...payload,
						};
					}

					return transaction;
				});
			}
		},
		[actions.DOWNLOAD_PENDING]: (state) => {
			if (state.downloading === false) {
				state.downloading = true;
			}
		},
		[actions.DOWNLOAD_FULFILLED]: (state) => ({
			...state,
			downloading: false,
		}),
		[actions.DOWNLOAD_ERROR]: (state, { error }) => ({
			...state,
			downloading: false,
			error,
		}),
	},
	extraReducers: {
		[fetchTransactions.pending]: (state) => {
			if (state.loading === false) {
				state.loading = true;
			}
		},
		[fetchTransactions.fulfilled]: (
			state,
			{ payload: { data, pagination } }
		) => ({
			...state,
			loading: false,
			data: data.map((transaction) => ({
				...transaction,
				id: transaction.paymentId,
			})),
			pagination,
		}),
		[fetchTransactions.rejected]: (state, { error }) => ({
			...state,
			loading: false,
			error,
		}),
	},
});

export const {
	downloadPending,
	downloadFulfilled,
	downloadError,
	setFilters,
	clear,
	setSuccess,
	updateTransaction,
} = transactionsSlice.actions;
export default transactionsSlice.reducer;
