import { put, call } from "redux-saga/effects";
import { takeLatestAsync } from "saga-toolkit";

import { api } from "api";
import { getPeriod } from "@utils";

import { fetchDashboard, setTransactions } from "./reducer";

function* handleFetch({ meta: { arg } }) {
  try {
    const { startDate, endDate } = getPeriod();
    const data = yield call(api.getTransactions, {
      TransactionDateTo: endDate,
      TransactionDateFrom: startDate,
      Page: 1,
      PageSize: 10,
      IsProduction: true
    });
    yield put(setTransactions(data?.data));
    return true;
  } catch (error) {
    throw new Error(error.message);
  }
}

export function* dashboardSaga() {
  yield takeLatestAsync(fetchDashboard.type, handleFetch);
}
