import { createSlice } from "@reduxjs/toolkit";

const navigatorLanguage = (navigator.language || navigator.userLanguage || "")
  .toLowerCase()
  .split("-")[0];

const initialState = {
  mode: localStorage.getItem("SYSTEM_THEME") || "dark", // Dark theme as default
  menuOpen: true,
  modals: [],
  locale: localStorage.getItem("SYSTEM_LANGUAGE") || navigatorLanguage || "en",
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    switchMode: (state, { payload }) => {
      localStorage.setItem("SYSTEM_THEME", payload);
      state.mode = payload || initialState.mode;
    },
    toggleMenu: (state, { payload }) => {
      state.menuOpen = payload || !state.menuOpen;
    },
    openModal: (state, { payload: modal }) => {
      state.modals = [
        ...state.modals,
        { ...modal, key: modal.key || new Date().getTime() + Math.random() },
      ];
    },
    closeModal: (state, { payload: key }) => {
      state.modals = state.modals.filter((modal) => modal.key !== key);
    },
    setLocale: (state, { payload }) => {
      state.locale = payload;
    },
  },
});

export const { switchMode, toggleMenu, openModal, closeModal, setLocale } =
  uiSlice.actions;

export default uiSlice.reducer;
