import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, errorType: null };
	}

	static getDerivedStateFromError(error) {
		const path = location.hash.split('?');
		const queryError = path[1] && path[1].split('error=')[1] ? path[1].split('error=')[1].slice(0, 3) : null;
		// Update state so the next render will show the fallback UI.
		return { hasError: true, errorType: queryError};
	}

	componentDidCatch(error, errorInfo) {
		console.log(error, errorInfo);
	}

	render() {
		const errors = {
			401: 'You do not have access to this page.',
			403: 'You do not have access to this page.',
			default: 'Something went wrong.',
		};

		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<Box style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
					<Typography variant="h1">{errors[this.state.errorType] || errors['default']}</Typography>
				</Box>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;