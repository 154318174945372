import GenericError from "./genericError";

class GenericResponseError extends GenericError {
    constructor(response) {
        const message = response.data?.error?.publicMessage || "Something went wrong";
        const statusCode = response.status;

        super(message, statusCode);
    }
}

export default GenericResponseError;
