import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 290,
    flexShrink: 0,
    whiteSpace: "nowrap",
    overflowX: "hidden",
  },
  drawerPaper: ({ offset }) => ({
    border: "none",
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,
    paddingBottom: 0,
    overflowX: "hidden",
    paddingLeft: 0,
    paddingRight: 0,
    top: `${offset}px`,
    maxHeight: `calc(100% - ${offset}px)`,
  }),
  drawerOpen: {
    width: "290px",
    [theme.breakpoints.up("lg")]: {
      width: 290,
    },
  },
  drawerClose: {
    width: 74,
  },
  menuClosed: {
    "& > div > div > div": {
      paddingLeft: "25px !important",

      "& span": {
        display: "none",
      },
    },
  },
  drawerPaperMobile: {
    width: '100vw',
    height: '100vh',
    paddingTop: '0px !important',
  },
  logoContainer: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
  },
  logoContainerClosed: {
    paddingLeft: 25,
    flexDirection: "column",

    "& > span": {
      marginTop: 20,

      "& > svg": {
        transform: "rotate(180deg)",
      },
    },
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  toolbar: {
    minHeight: 62,
    justifyContent: "space-between",
  },
  toolbarGutters: {
    paddingRight: 15,
    paddingLeft: 15,
  },
  menuButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  materialIcon: {
    fontSize: "30px",
    margin: "0 -3px",
  },
  toggleMenuIcon: {
    cursor: "pointer",
    "&:hover": {
      borderRadius: "100%",
      background: theme.type === "dark" ? "#3c3c3c" : theme.palette.lightGray.main,
      transition: "all .2s ease-in-out",
    },
  },
}));
