import { useContext } from "react";
import { BananaContext } from "@wikimedia/react.i18n";
import { useRecoilValue } from "recoil";

import { modalSelector } from "./state";

export function useModals() {

  const openModal = useRecoilValue(modalSelector);

  const translator = useContext(BananaContext);

  const openRefund = (props) => openModal({
    type: 'RefundModal',
    title: translator.i18n("refund-transaction"),
    props
  });

  const openCapture = (props) => openModal({
    type: 'CaptureModal',
    title: translator.i18n("capture-transaction"),
    props
  });

  const openDetail = (props) => openModal({
    type: 'DetailModal',
    title: " ",
    props
  });

  const openWebhookDetail = (props) => openModal({
    type: 'WebhookDetailModal',
    title: " ",
    props
  });

  const openShiftDetail = (props) => openModal({
    type: 'ShiftDetailModal',
    title: translator.i18n("shift-details"),
    props
  });

  const openOnboardingDetail = (props) => openModal({
    type: 'OnboardingDetail',
    title: translator.i18n("onboarding-details"),
    props
  });

  const openCreatedSocialPayment = (props) => openModal({
    type: 'CreatedSocialPayment',
    title: translator.i18n("social-payments-title"),
    props
  });

  const openSocialPaymentDetail = (props) => openModal({
    type: 'SocialPaymentDetail',
    title: translator.i18n("details"),
    props
  });

  const openChangePasswordStore = (props) => openModal({
    type: 'ChangeStorePassword',
    title: translator.i18n("change-terminal-password"),
    props
  });

  const openExportReportDetail = (props) => openModal({
    type: 'ExportReportModal',
    title: translator.i18n("export-details"),
    props
  });

  return {
    openRefund,
    openCapture,
    openDetail,
    openShiftDetail,
    openOnboardingDetail,
    openCreatedSocialPayment,
    openSocialPaymentDetail,
    openWebhookDetail,
    openExportReportDetail,
    openChangePasswordStore,
  };
}
