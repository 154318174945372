import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useStyles } from './styles';

export const FormSelectInput = ({ label, children, helperText = null, labelAction = null, ...props }) => {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Box className={classes.input} {...props}>
				<Box className={classes.title}>
					{typeof label === "string"
						? <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>{label}</Typography>
						: label
					}
					{labelAction}
				</Box>
				{children}
			</Box>
			{typeof helperText === "string"
				? <Box className={classes.helperText}><Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>{helperText}</Typography></Box>
				: <Box className={classes.helperText}>{helperText}</Box>
			}
		</Box>
	);
}

export default FormSelectInput;