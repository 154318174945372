import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function LogoutIcon(props) {
	return (
		<SvgIcon {...props}>
			<path d="M7.955 24h-4.449c-0.944 0-1.888-0.404-2.427-1.079-0.674-0.674-1.079-1.618-1.079-2.562v-16.719c0-0.944 0.404-1.888 0.944-2.562 0.674-0.674 1.618-1.079 2.562-1.079h4.449c0.674 0 1.213 0.539 1.213 1.213s-0.539 1.213-1.213 1.213h-4.449c-0.27 0-0.539 0.135-0.809 0.27-0.135 0.27-0.27 0.539-0.27 0.944v16.719c0 0.27 0.135 0.674 0.27 0.809 0.27 0.27 0.539 0.404 0.809 0.404h4.449c0.674 0 1.213 0.539 1.213 1.213s-0.539 1.213-1.213 1.213zM17.393 18.607c-0.27 0-0.674-0.135-0.809-0.404-0.539-0.539-0.539-1.213 0-1.753l4.584-4.584-4.584-4.584c-0.539-0.539-0.539-1.213 0-1.753s1.213-0.539 1.753 0l5.393 5.393c0.539 0.539 0.539 1.213 0 1.753l-5.393 5.393c-0.27 0.404-0.674 0.539-0.944 0.539zM22.787 13.213h-14.831c-0.674 0-1.213-0.539-1.213-1.213s0.539-1.213 1.213-1.213h14.831c0.674 0 1.213 0.539 1.213 1.213s-0.539 1.213-1.213 1.213z"></path>
		</SvgIcon>
	);
}

export default LogoutIcon;