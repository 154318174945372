import { createSlice } from '@reduxjs/toolkit';
import { createSagaAction } from 'saga-toolkit';

const name = "keys";

export const actions = {
	FETCH: `${name}/fetchKeys`,
	GENERATE: `${name}/generateKey`,
}

export const fetchKeys = createSagaAction(actions.FETCH);
export const generateKey = createSagaAction(actions.GENERATE);

export const keysSlice = createSlice({
	name,
	initialState: {
		loading: false,
		error: null,
		success: null,
		keys: null,
	},
	reducers: {
		clear: (state) => ({ ...state, error: null, success: null }),
	},
	extraReducers: {
		[fetchKeys.pending]: (state) => ({ ...state, loading: true }),
		[fetchKeys.fulfilled]: (state, { payload: { keys }}) => ({ ...state, loading: false, success: true, keys }),
		[fetchKeys.rejected]: (state) => ({ ...state, loading: false, error: true }),
		[generateKey.pending]: (state) => ({ ...state, loading: true }),
		[generateKey.fulfilled]: (state) => ({ ...state, loading: false, success: true }),
		[generateKey.rejected]: (state) => ({ ...state, loading: false, error: true }),
	}
});

export const { clear } = keysSlice.actions;
export default keysSlice.reducer;