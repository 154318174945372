import React from 'react';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

const types = () => {
	const theme = useTheme();

	return {
		'success': {
			sx: {
				color: theme.palette.green.main,
				backgroundColor: theme.palette.green.badge,
				padding: '2px 6px',
			},
			icon: <CheckRoundedIcon sx={{ height: '14px', width: '14px', margin: '2px 5px', backgroundColor: `${theme.palette.green.main} !important`, borderRadius: '50%', color: `${theme.palette.background.default} !important` }} />
		},
		'created': {
			sx: {
				color: theme.palette.blue.main,
				backgroundColor: theme.palette.blue.badge,
				padding: '2px 6px',
			},
			icon: <CheckRoundedIcon sx={{ height: '14px', width: '14px', margin: '2px 5px', backgroundColor: `${theme.palette.blue.main} !important`, borderRadius: '50%', color: `${theme.palette.background.default} !important` }} />
		},
		'error': {
			sx: {
				color: theme.palette.red.main,
				backgroundColor: theme.palette.red.badge,
				padding: '2px 6px',
			},
			icon: <ClearRoundedIcon sx={{ height: '14px', width: '14px', margin: '2px 5px', backgroundColor: `${theme.palette.red.main} !important`, borderRadius: '50%', color: `${theme.palette.background.default} !important` }} />
		},
		'expired':
		{
			sx: {
				color: theme.palette.blue.main,
				backgroundColor: theme.palette.blue.badge,
				padding: '2px 6px',
			},
			icon: <ClearRoundedIcon sx={{ height: '14px', width: '14px', margin: '2px 5px', backgroundColor: `${theme.palette.blue.main} !important`, borderRadius: '50%', color: `${theme.palette.background.default} !important` }} />
		},
		'pending': {
			sx: {
				color: theme.palette.yellow.main,
				backgroundColor: theme.palette.yellow.badge,
				padding: '2px 6px',
			},
			icon: <MoreHorizRoundedIcon sx={{ height: '14px', width: '14px', margin: '2px 5px', backgroundColor: `${theme.palette.yellow.main} !important`, borderRadius: '50%', color: `${theme.palette.background.default} !important` }} />
		},
		'processing': {
			sx: {
				color: theme.palette.orange.main,
				backgroundColor: theme.palette.orange.badge,
				padding: '2px 6px',
			},
			icon: <MoreHorizRoundedIcon sx={{ height: '14px', width: '14px', margin: '2px 5px', backgroundColor: `${theme.palette.orange.main} !important`, borderRadius: '50%', color: `${theme.palette.background.default} !important` }} />
		}
	}
}

export function Tag({ type, showChip = true, ...props }) {
	const tagInfo = types()[type];
	return showChip ? <Chip {...props} {...tagInfo} /> : <>{tagInfo.icon}</>;
}

export default Tag;