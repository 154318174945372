import { gql } from "@apollo/client";

export const GET_TRANSACTIONS = gql`
  query GetTransactions {
    transactions(
      filters: {
        initiatedFrom: [1]
        journalStates: [1]
        personIds: [10]
        endDateUtc: "2021-08-30T23:59:59-03:00"
        startDateUtc: "2021-08-30T00:00:00-03:00"
        isOrderAsc: true
        pageSize: 10
        pageNumber: 1
      }
    ) {
      data {
        paymentId
      }
    }
  }
`;

export const GET_SHIFTS = gql`
  query GetShifts(
    $startDate: String!
    $endDate: String!
    $units: [Int!]!
    $pageNumber: Int!
    $pageSize: Int!
    $code: String
  ) {
    shifts(
      filters: {
        personIds: $units
        pageNumber: $pageNumber
        pageSize: $pageSize
        startDateUtc: $startDate
        endDateUtc: $endDate
        code: $code
      }
    ) {
      data {
        id
        status
        code
        closingUserEmail
        startDate
        endDate
        personName
        balanceAmount
        refundCount
        refundAmount
        paymentCount
        paymentAmount
      }
      pagination {
        totalItemsAvailable
        pageNumber
        pageSize
      }
    }
  }
`;

export const ONBOARD = gql`
  query {
    onboard {
      phase
      data {
        merchantId
        userEmail
        id
        onboardUrl
      }
    }
  }
`;

export const SEND_ONBOARDING_INVITE = gql`
  mutation SendOnboardingInvite($invite: InputInvite!) {
    createInvite(invite: $invite) {
      phase
      data {
        onboardUrl
      }
    }
  }
`;

export const GET_INVOICES = gql`
  query GetInvoices(
    $personIds: [Int!]!
    $year: Int!
    $pageNumber: Int!
    $pageSize: Int!
  ) {
    invoices(
      filters: {
        personIds: $personIds
        year: $year
        pageNumber: $pageNumber
        pageSize: $pageSize
      }
    ) {
      pagination {
        totalItemsAvailable
        pageNumber
        pageSize
      }
      data {
        month
        year
        pdfName
        totalAmount
        externalLink
      }
    }
  }
`;

export const GET_SETTLEMENTS = gql`
  query GetSettlements(
    $personIds: [Int!]!
    $year: Int!
    $month: Int!
    $pageNumber: Int!
    $pageSize: Int!
  ) {
    settlements(
      filters: {
        personIds: $personIds
        year: $year
        month: $month
        pageNumber: $pageNumber
        pageSize: $pageSize
      }
    ) {
      pagination {
        totalItemsAvailable
        pageNumber
        pageSize
      }
      data {
        amount
        settlementDate
        reference
        personId
        id
        acquirer {
          acquirerName
        }
      }
    }
  }
`;

export const GET_SETTLEMENT = gql`
  query getSettlement($settlementId: ID!, $pageNumber: Int!, $pageSize: Int!) {
    settlementDetails(
      filters: {
        settlementId: $settlementId
        pageNumber: $pageNumber
        pageSize: $pageSize
      }
    ) {
      pagination {
        totalItemsAvailable
        pageNumber
        pageSize
      }
      data {
        cardScheme
        settlementDate
        orderRef
        commissionAmount {
          decimalAmount
        }
        transactionType
        transactionAmount {
          decimalAmount
        }
        personId
      }
    }
  }
`;

export const GET_ONBOARDING_PROCESSES = gql`
  query GetOnboardProcesses(
    $startDate: String!
    $endDate: String!
    $channel: String
    $status: String
    $pageNumber: Int!
    $pageSize: Int!
  ) {
    onboardProcesses(
      filters: {
        startDate: $startDate
        endDate: $endDate
        channel: $channel
        status: $status
        pageNumber: $pageNumber
        pageSize: $pageSize
      }
    ) {
      pagination {
        totalItemsAvailable
        pageNumber
        pageSize
      }
      data {
        externalId
        reference
        channel
        status
        createdAtUtc
        partnerEmail
        userEmail
        contactPerson
        firstName
        lastName
      }
    }
  }
`;

export const CREATE_SOCIAL_PAYMENT = gql`
  mutation CreateAds(
    $title: String!
    $description: String!
    $value: Long!
    $pictureBase64: String!
    $stockQuantity: Int!
    $validityDays: Short!
    $collectShippingData: Boolean!
    $nationalShippingValue: Long
    $urgentNationalShippingValue: Long
    $internationalShippingValue: Long
    $urgentInternationalShippingValue: Long
    $personId: Int!
    $reference: String!
  ) {
    createAds(
      ads: {
        title: $title
        description: $description
        value: $value
        reference: $reference
        validityDays: $validityDays
        pictureBase64: $pictureBase64
        stockQuantity: $stockQuantity
        collectShippingData: $collectShippingData
        nationalShippingValue: $nationalShippingValue
        urgentNationalShippingValue: $urgentNationalShippingValue
        internationalShippingValue: $internationalShippingValue
        urgentInternationalShippingValue: $urgentInternationalShippingValue
        personId: $personId
      }
    ) {
      url
    }
  }
`;

export const EDIT_SOCIAL_PAYMENT = gql`
  mutation UpdateAds(
    $externalId: Guid!
    $title: String
    $description: String
    $value: Long
    $pictureBase64: String
    $stockQuantity: Int
    $validityDays: Short
    $collectShippingData: Boolean
    $nationalShippingValue: Long
    $urgentNationalShippingValue: Long
    $internationalShippingValue: Long
    $urgentInternationalShippingValue: Long
  ) {
    updateAds(
      externalId: $externalId
      ads: {
        title: $title
        description: $description
        value: $value
        reference: "123"
        validityDays: $validityDays
        pictureBase64: $pictureBase64
        stockQuantity: $stockQuantity
        collectShippingData: $collectShippingData
        nationalShippingValue: $nationalShippingValue
        urgentNationalShippingValue: $urgentNationalShippingValue
        internationalShippingValue: $internationalShippingValue
        urgentInternationalShippingValue: $urgentInternationalShippingValue
      }
    ) {
      url
    }
  }
`;

export const GET_SOCIAL_PAYMENTS = gql`
  query GetSocialPayments(
    $startDate: String!
    $endDate: String!
    $pageNumber: Int!
    $pageSize: Int!
    $personId: [Int!]!
  ) {
    ads(
      filters: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        startDateUtc: $startDate
        endDateUtc: $endDate
        personId: $personId
      }
    ) {
      data {
        externalId
        title
        description
        value
        url
        reference
        stockQuantity
        expireAt
        collectShippingData
        nationalShippingValue
        urgentNationalShippingValue
        internationalShippingValue
        urgentInternationalShippingValue
      }
      pagination {
        totalItemsAvailable
        pageNumber
        pageSize
      }
    }
  }
`;

export const GET_SOCIAL_PAYMENT = gql`
  query GetSocialPayment($externalId: Guid!) {
    adsDetails(externalId: $externalId) {
      externalId
      title
      description
      value
      url
      reference
      stockQuantity
      pictureUrl
      validityDays
      collectShippingData
      nationalShippingValue
      urgentNationalShippingValue
      internationalShippingValue
      urgentInternationalShippingValue
      personId
    }
  }
`;
