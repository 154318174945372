import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function RevolutPayIcon(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="20 -90 163.2 425"
      style={{ width: 40, height: 40 }}
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <g>
        <circle cx="90" cy="130" r="200" fill="#F5F5F5"></circle>
        <rect y="65.9" fill="#010202" width="50.55" height="199.81" />
        <path
          fill="#010202"
          d="M130.7,154.67v-2.11c41.13,0,74.48-33.35,74.48-74.48C205.18,36.96,171.83,0,130.7,0H0v43.33h97.65   c41.13,0,58.53,8.87,58.53,32.8c0,23.93-18.3,35.21-72.22,35.21H72.82v43.33l79.33,111.04h61.05L130.7,154.67z"
        />
      </g>
    </SvgIcon>
  );
}

export default RevolutPayIcon;
