import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';

import { styled } from '@mui/system';

export const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  paddingTop: 10,
  paddingBottom: 10,
  height: '48px',
  margin: '10px 20px 10px 20px',
  color: theme.palette.text.main,
  backgroundColor: 'transparent',
  borderRadius: '5px',
  fontSize: `${theme.typography.body2.fontSize}px`,
  "& > .MuiListItemText-root": {
    padding: '5px 10px',
    ".MuiListItemText-primary": {
      fontSize: `${theme.typography.body2.fontSize}px`
    }
  },
  "&.Mui-selected": {
    color: theme.palette.text.main,
    backgroundColor: theme.palette.lightGray.main,
  },
  "&.Mui-selected > *": {
    color: theme.palette.text.main,
  },
  "&.Mui-selected::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    background: 'linear-gradient(0deg, rgba(32,32,32,0) 0%, rgba(99,57,239,1) 28%, rgba(99,57,239,1) 73%, rgba(32,32,32,0) 100%)',
    height: '48px',
    width: '3px',
    left: '0px',
    backgroundColor: '#2b2b2b',
  },
  "&:hover": {
    color: theme.palette.text.main,
    backgroundColor: theme.palette.lightGray2.main
  },
  "&:last-child::before": {
    content: '""',
    height: '0px',
  },
}));

export const ListItemButtonChild = styled(MuiListItemButton)(({ theme }) => ({
  padding: '10px 25px 10px 20px',
  borderRadius: '2px',
  height: '50px',
  color: theme.palette.text.main,
  "&:hover": {
    color: theme.palette.text.primary,
    backgroundColor: 'transparent'
  },
  "& > .MuiListItemText-root": {
    padding: '5px 10px',
    ".MuiListItemText-primary": {
      fontSize: `${theme.typography.body2.fontSize}px`
    }
  },
  "&:hover > *": {
    color: theme.palette.text.primary
  },
  "&:hover::after": {
    opacity: 1,
  },
  "&::before": {
    content: '""',
    position: 'absolute',
    top: 0,
    width: 2,
    height: '100%',
    backgroundColor: '#8a8a8a',
    left: '45px'
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: '39px',
    transform: "translateY(-50%)",
    width: 14,
    height: 14,
    borderRadius: '50%',
    border: `3px solid ${theme.palette.background.paper}`,
    backgroundColor: '#8a8a8a',
  },
  "&:last-child::before": {
    content: '""',
    height: '50%'
  },
  "&.Mui-selected": {
    backgroundColor: 'transparent !important',
  },
  "&.Mui-selected > .MuiListItemText-root": {
    padding: '5px 10px',
    borderRadius: '2px',
    height: '32px',
    color: theme.palette.white.main,
    backgroundColor: `${theme.palette.primary.main} !important`,
    "& > *": {
      color: `${theme.palette.white.main} !important`,
    },
  },
  "&.Mui-selected > *": {
    color: theme.palette.text.primary,
  },
  "&.Mui-selected:hover": {
    color: "white",
    "& .MuiListItemIcon-root": {
      color: "white"
    }
  },
  "&.Mui-selected::before": {
    content: '""',
    position: 'absolute',
    top: 0,
    width: 2,
    height: '100%',
    backgroundColor: '#8a8a8a',
    left: '45px',
  },
  "&.Mui-selected:last-child::before": {
    content: '""',
    height: '50%'
  },
  "&.Mui-selected::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: '39px',
    transform: "translateY(-50%)",
    width: 14,
    height: 14,
    borderRadius: '50%',
    border: `3px solid ${theme.palette.background.paper}`,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
}));


export const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  marginTop: 0,
  marginBottom: 0,
  display: 'block',
  opacity: 1,
  fontSize: theme.typography.body2.fontSize,
}));

export const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  minWidth: 36,
  color: theme.palette.text.disabled,
}));

