import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function IdealIcon(props) {
	return (
		<SvgIcon {...props} viewBox="20 -15 40 110" style={{ color: 'white', width:40, height:40, backgroundColor: '#d50072', borderRadius: '100%' }}>
			<path fill="none" d="M0 0h88.668v79.598H0z"/><path d="M7.375 7.38v64.877h37.824c24.951 0 35.771-13.973 35.771-32.502 0-18.457-10.82-32.375-35.771-32.375H7.375z"/><path d="M11.463 11.472h33.741c22.901 0 31.679 12.506 31.679 28.282 0 18.917-12.258 28.409-31.679 28.409H11.463V11.472zm3.271 3.271v50.151h30.47c18.396 0 28.405-8.572 28.405-25.14 0-17.027-10.85-25.012-28.405-25.012h-30.47z"/><path color="#d50072" d="M18.822 43.442h9.754v17.36h-9.754z"/><circle color="#d50072" cx="23.698" cy="34.695" r="6.062" stroke="#d50072" strokeWidth="2" /><path d="M49.031 37.531v2.811h-6.95v-11.28h6.728v2.81h-3.918v1.306h3.705v2.811h-3.705v1.543l4.14-.001zm1.216 2.813l3.401-11.286h3.998l3.4 11.286h-2.925l-.638-2.183h-3.676l-.639 2.183h-2.921zm4.384-4.991h2.034l-.933-3.188h-.17l-.931 3.188zm7.828-6.294h2.812v8.473h4.162c-1.142-15.374-13.234-18.696-24.228-18.696H33.488v10.229h1.734c3.162 0 5.125 2.144 5.125 5.594 0 3.56-1.916 5.685-5.125 5.685h-1.734V60.81h11.718c17.866 0 24.088-8.298 24.308-20.468h-7.055V29.059zm-28.973 2.816v5.657h1.736c1.203 0 2.315-.348 2.315-2.874 0-2.467-1.237-2.783-2.315-2.783h-1.736z" fill="#d50072"/>
		</SvgIcon>
	);
}

export default IdealIcon;
