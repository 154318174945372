import React from "react";
import Box from "@mui/material/Box";

import PaybyrdLogo from "@components/atoms/PaybyrdLogo";

export const Loading = () => {
  return (
    <Box sx={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <PaybyrdLogo id={'loading'} open={false} />
    </Box>
  );
};

export default Loading;
