import { createSlice } from '@reduxjs/toolkit';

const name = "shifts";

export const actions = {
	DOWNLOAD: `${name}/shiftDownload`,
	DOWNLOAD_PENDING: `downloadPending`,
	DOWNLOAD_FULFILLED: `downloadFulfilled`,
	DOWNLOAD_ERROR: `downloadError`,
}

export const shiftsSlice = createSlice({
	name,
	initialState: {
		downloading: false,
		error: null,
	},
	reducers: {
		[actions.DOWNLOAD_PENDING]: (state) => {
			if (state.downloading === false) {
				state.downloading = true;
			}
		},
		[actions.DOWNLOAD_FULFILLED]: state => ({ ...state, downloading: false }),
		[actions.DOWNLOAD_ERROR]: (state, {error}) => ({ ...state, downloading: false, error }),
	},
});

export const { downloadPending, downloadFulfilled, downloadError } = shiftsSlice.actions;
export default shiftsSlice.reducer;