import { useSelector } from "react-redux";

export function useUser() {
	const { merchants, scopes, email } = useSelector((state) => state.user);

	const userHasScope = (userScope) => {
		if (Array.isArray(userScope)) {
			let hasAllPermissions = false;

			userScope.map((scope) => {
				hasAllPermissions = scopes.includes(scope);

				return scope;
			});

			return hasAllPermissions;
		}

		return scopes.includes(userScope);
	};

	return { merchants, scopes, userHasScope, email };
}
