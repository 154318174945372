import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export function LogoIcon(props) {

  return (
    <SvgIcon
      {...props}
      viewBox="580 440 10 200"
      style={{ width: 40, height: 30 }}
    >
      <g>
        <linearGradient
          id={`SVGID_1_${props.id}`}
          gradientUnits="userSpaceOnUse"
          x1="441.0464"
          y1="540.2205"
          x2="646.6885"
          y2="540.2205"
        >
          <stop offset="0" style={{ stopColor: "#5237DB" }}></stop>
          <stop offset="0.0704" style={{ stopColor: "#573EDD" }}></stop>
          <stop offset="0.4584" style={{ stopColor: "#705FE6" }}></stop>
          <stop offset="0.7821" style={{ stopColor: "#7F73EB" }}></stop>
          <stop offset="1" style={{ stopColor: "#857AED" }}></stop>
          <stop offset="1" style={{ stopColor: "#715EE6" }}></stop>
        </linearGradient>
        <path
          fill={`url(#SVGID_1_${props.id}`}
          d="M634.81,475.01c-12.5-17.92-32.28-27.74-52.56-28.25v-0.03h-97.87v0.37c0.24,0.89,0.5,1.78,0.81,2.67
					c4.37,12.74,15.07,25.73,26.77,31.55c24.38,12.12,52.03-5.06,76.84,5.47c9.42,4,16.95,11.92,20.97,21.27
					c0.95,2.21,5.59,16.15,2.97,14.75c-18.04-9.64-41.61,0.26-56.97,13.01c-7.26,6.02-14.11,12.57-20.2,19.77
					c-3.06,3.62-5.93,7.4-8.56,11.34c-1.32,1.98-2.59,4.01-3.79,6.07c-0.83,1.43-1.46,2.59-1.69,3.02c-0.64,1.19-1.2,2.27-1.67,3.21
					c-0.78-0.02-1.97-0.04-3.42-0.05c-2.83-0.02-4.93,0.02-5.8,0.03c-3.32,0.01-6.64,0.04-9.96,0.04c-7.42,0-24.84,0-36.13,0
					l-23.49,54.46h32.35c13.97,0,25.3-9.54,31.38-21.76c5.03-10.9,10.05-21.81,15.08-32.71c20.8-0.13,41.6-0.25,62.4-0.38v-0.02
					c12.53-0.3,25.1-4.14,36.15-11.85C648.33,546.12,655.68,504.94,634.81,475.01z"
        ></path>
      </g>
    </SvgIcon>
  );
}

export default LogoIcon;
