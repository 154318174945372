import { createSlice } from '@reduxjs/toolkit';
import { createSagaAction } from 'saga-toolkit';

const name = "paybylink";

export const actions = {
	SEND: `${name}/sendPaybyLink`,
}

export const sendPayByLink = createSagaAction(actions.SEND);

export const paybylinkSlice = createSlice({
	name,
	initialState: {
		loading: false,
		error: null,
		success: null,
	},
	reducers: {
		clear: (state) => ({ ...state, error: null, success: null }),
	},
	extraReducers: {
		[sendPayByLink.pending]: (state) => ({ ...state, loading: true }),
		[sendPayByLink.fulfilled]: (state) => ({ ...state, loading: false, success: true }),
		[sendPayByLink.rejected]: (state) => ({ ...state, loading: false, error: true }),
	}
});

export const { clear } = paybylinkSlice.actions;
export default paybylinkSlice.reducer;