import { atom } from 'recoil';
import modalSelector from './selectors';

const modalAtom = atom({
  key: 'modalAtom',
  default: {
    modals: []
  },
});

export default modalAtom;

export { modalSelector };