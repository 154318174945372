import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  open: {
    paddingLeft: 0,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 290,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 290,
    },
  },
  close: {
    paddingLeft: 0,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 74,
    },
  },
}));
