import axios from "axios";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { NODE_ENV, API_URL, BFF_API, DEFAULT_PAGE_SIZE } from "@services/config";
import { ONBOARD } from "./queries";
import { isForbidden, handleAPIError } from "@utils";

import ForbiddenError from "common/errors/forbiddenError";
import NotFoundError from "common/errors/notFoundError";
import GenericResponseError from "common/errors/genericResponseError";

let apollo = null;
const dev = process.env.NODE_ENV === "development";
const stage = window.location.href.includes("dashboard-s");

function initApollo(accessToken) {
  const httpLink = createHttpLink({
    uri: `https://webapp-paybyrd-bff${
      dev || stage ? "-s" : ""
    }.azurewebsites.net/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    };
  });

  if (!apollo) {
    apollo = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "no-cache",
          errorPolicy: "ignore",
        },
        query: {
          fetchPolicy: "no-cache",
          errorPolicy: "all",
        },
      },
    });
  }
}

export const api = {
  init: (accessToken) => {
    const isDev = NODE_ENV === "development";

    axios.defaults.validateStatus = function () {
      return true;
    };
    axios.defaults.baseURL = `${
      isDev ? window.location.origin : API_URL
    }/api/v1/`;
    axios.defaults.headers.common = {
      Authorization: `Bearer ${accessToken}`,
    };

    initApollo(accessToken);
  },
  resetLocaleAndTimezoneHeaders: () => {},
  getUserInfo: async () => {
    const {
      data: { data },
      status,
    } = await axios.get(`merchant`, {
      params: { merchantIds: [] },
      headers: { "x-api-version": "2.0" },
    });

    if (isForbidden(status)) {
      handleAPIError(status);
    }

    return data;
  },
  userInfo: async () => {
    const { data: { data: { activityBranches = [] } } = {} } = await axios.get(
      `userinfo`,
      {
        baseURL: BFF_API,
      }
    );

    return activityBranches;
  },
  getCurrencies: async (merchantIds = []) => {
    const {
      data: { data: { currencies = [] } = {} },
    } = await axios.get(`currencies`, {
      baseURL: BFF_API,
      params: { merchantIds },
    });

    return currencies;
  },
  getCurrenciesKeyValue: async (merchantIds = []) => {
    const {
      data: { data: { currencies = [] } = {} },
    } = await axios.get(`currencies`, {
      baseURL: BFF_API,
      params: { merchantIds },
    });

    return currencies.map((currency) => ({ value: currency, label: currency }));
  },
  getPaymentMethods: async () => {
    const {
      data: { data },
    } = await axios.get(`brands/payment-methods`, {
      baseURL: BFF_API,
    });

    return data.map(({ paymentMethod }) => ({
      value: paymentMethod,
      label: paymentMethod,
    }));
  },
  getGroups: async (page = 1, name = "") => {
    const { data } = await axios.get(`groups`, {
      baseURL: BFF_API,
      params: {
        Page: page,
        PageSize: 50,
        BusinessName: name,
      },
    });

    return (data?.data || []).map(({ id, businessName }) => ({
      value: id,
      label: businessName,
      id,
    }));
  },
  getMerchants: async (page = 1, name = "") => {
    const { data } = await axios.get(`merchants`, {
      baseURL: BFF_API,
      params: {
        Page: page,
        PageSize: 50,
        BusinessName: name,
      },
    });

    return (data?.data || []).map(({ id, businessName }) => ({
      value: id,
      label: businessName,
      id,
    }));
  },
  getStores: async (page = 1, name = "") => {
    const { data } = await axios.get(`stores`, {
      baseURL: BFF_API,
      params: {
        Page: page,
        PageSize: 50,
        BusinessName: name,
      },
    });

    return (data?.data || []).map(({ id, businessName }) => ({
      value: id,
      label: businessName,
      id,
    }));
  },
  getPaginatedStores: async (page = 1, count = 10, name = "") => {
    const { data, headers } = await axios.get(`stores`, {
      baseURL: BFF_API,
      params: {
        Page: page,
        PageSize: count,
        BusinessName: name,
      },
    });

    const pageNumber = parseInt(headers["x-current-page"]);
    const pageSize = parseInt(headers["x-page-size"]);
    const rowCount = parseInt(headers["x-total-item-count"]);

    return {
      data: (data?.data || []).map(({ id, businessName }) => ({
        value: id,
        label: businessName,
        id,
      })),
      pagination: { pageNumber, pageSize, rowCount },
    };
  },
  getChannels: async (page = 1, name = "") => {
    const { data } = await axios.get(`channels`, {
      baseURL: BFF_API,
      params: {
        Page: page,
        PageSize: 999, // TODO We dont have pagination for this endpoint yet
        ApplicationName: name,
      },
    });

    // TODO BE doesnt support id yet
    return (data?.data || []).map(({ name }) => ({
      value: name,
      label: name,
      id: name,
    }));
  },
  getCountries: async (page = 1, name = "") => {
    const { data } = await axios.get(`countries`, {
      baseURL: BFF_API,
      params: {
        Page: page,
        PageSize: 50,
        Name: name,
      },
    });

    return (data?.data || []).map(({ threeLetterCode, countryName }) => ({
      value: threeLetterCode,
      label: countryName,
    }));
  },
  getBalanceReports: async (params) => {
    const result = await axios.get(
      `transactions/balance/${params.interval ?? "daily"}/reports`,
      {
        baseURL: BFF_API,
        params: { ...params, isProduction: true },
      }
    );

    if (result.status >= 400) {
      if (result.status === 403) {
        throw new ForbiddenError("delete-attachment-forbidden-error");
      }

      if (result.status === 404) {
        throw new NotFoundError("attachment-not-found-error");
      }

      throw new GenericResponseError(result);
    }

    const data = result?.data?.data;
    return data;
  },
  getTransactionsBalanceTotalizators: async (params) => {
    const {
      data: { data },
    } = await axios.get(`transactions/balance/totalizators`, {
      baseURL: BFF_API,
      params,
    });

    return data;
  },
  getTransactions: async (params) => {
    const { data, headers } = await axios.get(`transactions`, {
      baseURL: BFF_API,
      params,
    });

    const pageNumber = parseInt(headers["x-current-page"]);
    const pageSize = parseInt(headers["x-page-size"]);
    const totalAvailable = parseInt(headers["x-total-item-count"]);

    return {
      data: data?.data || [],
      error: data?.error,
      pagination: { pageNumber, pageSize, totalAvailable },
    };
  },
  getTransaction: async (id, storeId) => {
    const { data } = await axios.get(`transactions/${id}`, {
      baseURL: BFF_API,
      headers: { "x-store-id": storeId },
    });

    return data;
  },
  getOrder: async (id, storeId) => {
    const { data } = await axios.get(`orders/${id}`, {
      baseURL: BFF_API,
      headers: { "x-store-id": storeId },
    });

    return data;
  },
  getOrders: async (params) => {
    const { data, headers } = await axios.get(`orders`, {
      baseURL: BFF_API,
      params,
    });

    const pageNumber = parseInt(headers["x-current-page"]);
    const pageSize = parseInt(headers["x-page-size"]);
    const totalAvailable = parseInt(headers["x-total-item-count"]);

    return {
      data: data?.data || [],
      pagination: { pageNumber, pageSize, totalAvailable },
    };
  },

  getOrderTransactions: async (orderId, storeId) => {
    const {
      data: { data },
    } = await axios.get(`orders/${orderId}/transactions`, {
      baseURL: BFF_API,
      headers: { "x-store-id": storeId },
    });

    return data;
  },
  getChargebacks: async (params) => {
    const { data, headers } = await axios.get(`chargebacks`, {
      baseURL: BFF_API,
      params,
    });
    const pageNumber = parseInt(headers["x-current-page"]);
    const pageSize = parseInt(headers["x-page-size"]);
    const totalAvailable = parseInt(headers["x-total-item-count"]);

    return {
      data: data?.data || [],
      pagination: { pageNumber, pageSize, totalAvailable },
    };
  },
  getChargebackReasons: async (page = 1, name = "", brand = "") => {
    const { data } = await axios.get(`chargebacks/reasons/creations`, {
      baseURL: BFF_API,
      params: {
        Page: page,
        PageSize: 50,
        Brand: brand,
        Filter: name,
      },
    });

    return (data?.data || []).map(({ code, id, description }) => ({
      value: code,
      label: `${description} - ${code}`,
      id: id,
    }));
  },
  createChargeback: async (params) => {
    const { chargebackRef, currency, amount, storeId, transactionId, reason } = params;
    const result = await axios.post(
      `chargebacks`,
      {
        chargebackRef,
        currency,
        storeId: parseInt(storeId),
        transactionId,
        isoAmount: amount > 1 ? amount * 100 : amount,
        creationReasonId: reason,
      },
      {
        baseURL: BFF_API,
      }
    );
    return result;
  },
  chargeback: async (id) => {
    const result = await axios.get(`chargebacks/${id}`, {
      baseURL: BFF_API,
    });
    return result;
  },
  deleteAttachment: async (id, attachmentId) => {
    const result = await axios.delete(
      `chargebacks/${id}/attachments/${attachmentId}`,
      {
        baseURL: BFF_API,
      }
    );

    if (result.status >= 400) {
      if (result.status === 403) {
        throw new ForbiddenError("delete-attachment-forbidden-error");
      }

      if (result.status === 404) {
        throw new NotFoundError("attachment-not-found-error");
      }

      throw new GenericResponseError(result);
    }

    return result;
  },
  createPayByLink: async ({
    value,
    reference = "",
    name,
    email,
    phone,
    unit,
    validity = 1,
    culture,
    mode,
    countryCode,
    currency,
  }) => {
    let sendBy = ["Email"];
    const [firstName, ...rest] = name.split(" ");

    if (phone) {
      // TODO Support whatsapp when BE supports it
      sendBy = sendBy.concat(["SMS"]);
    }

    const { data, status } = await axios.post(
      `orders`,
      {
        isoAmount: Math.round(value * 100),
        currency: currency,
        orderRef: reference,
        expiresIn: validity,
        shopper: {
          email: email,
          firstName,
          lastName: rest.length > 0 ? rest[0] : "",
          phoneNumber: phone,
          phoneCountryCode: countryCode,
        },
        orderOptions: {
          culture,
          sendBy,
        },
        paymentOptions: {
          useSimulated: dev,
          cardOptions: {
            isPreAuth: mode === "preauth",
          },
        },
        personId: unit,
      },
      {
        baseURL: `${API_URL}/api/v2`,
      }
    );
    return { data, status };
  },
  download: async (params, type) => {
    const { data } = await axios.get(`${type}/download`, {
      params: params,
      responseType: "blob",
      headers: { Accept: "application/vnd.ms-excel" },
      baseURL: type === "transactions" ? BFF_API : `${API_URL}/api/v2`,
    });

    return data;
  },
  queueReportProcessing: async (params, type) => {
    const data = await axios.post(`reports/${type}`, params, {
      baseURL: BFF_API,
    });

    return data;
  },
  refund: async (paymentId, amount) => {
    const { data } = await axios.post(
      `refund/${paymentId}`,
      { amount },
      {
        baseURL: `${API_URL}/api/v2`,
      }
    );
    return data;
  },
  capture: async (paymentId, amount) => {
    const { data } = await axios.post(
      `capture/${paymentId}`,
      { amount },
      {
        baseURL: `${API_URL}/api/v2`,
      }
    );
    return data;
  },
  release: async (paymentId) => {
    const { data } = await axios.post(
      `release/${paymentId}`,
      {},
      {
        baseURL: `${API_URL}/api/v2`,
      }
    );
    return data;
  },
  downloadTransaction: async (transactionId) => {
    const { data } = await axios.get(`transactions/${transactionId}/download`, {
      params: {
        receiptType: "pdf",
      },
      responseType: "blob",
      headers: { Accept: "application/pdf" },
      baseURL: `${API_URL}/api/v2`,
    });
    return data;
  },
  downloadShift: async (shiftId) => {
    const { data } = await axios.get(`shifts/${shiftId}/download`, {
      params: {
        receiptType: "pdf",
      },
      responseType: "blob",
      headers: { Accept: "application/pdf" },
      baseURL: `${API_URL}/api/v2`,
    });
    return data;
  },
  onboard: async () => {
    const { data, errors } = await apollo.query({
      query: ONBOARD,
    });

    return { data, errors };
  },
  uploadFile: async (file, aggregation) => {
    const formData = new FormData();
    formData.append("File", file);
    const result = await axios.post(`attachments/${aggregation}`, formData, {
      baseURL: BFF_API,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return result;
  },
  chargebackDispute: async ({ id, message }) => {
    const result = await axios.post(
      `chargebacks/${id}/dispute`,
      { remark: message },
      {
        baseURL: BFF_API,
      }
    );

    return result;
  },
  chargebackAccept: async (chargebackId) => {
    const result = await axios.post(
      `chargebacks/${chargebackId}/accept`,
      { remark: "Chargeback accepted!" },
      {
        baseURL: BFF_API,
      }
    );

    return result;
  },
  chargebackWin: async (chargebackId) => {
    const result = await axios.post(
      `chargebacks/${chargebackId}/win`,
      { remark: "Chargeback won" },
      {
        baseURL: BFF_API,
      }
    );

    return result;
  },
  chargebackLose: async (chargebackId, remark) => {
    const result = await axios.post(
      `chargebacks/${chargebackId}/lose`,
      { remark: remark ? remark : "Chargeback lost" },
      {
        baseURL: BFF_API,
      }
    );

    return result;
  },
  chargebackRequestInformation: async ({ id, message }) => {
    const result = await axios.post(
      `chargebacks/${id}/request-information`,
      {
        remark: message,
      },
      {
        baseURL: BFF_API,
      }
    );

    return result;
  },
  chargebackSendMessage: async ({ id, message }) => {
    const result = await axios.post(
      `chargebacks/${id}/remarks`,
      {
        remark: message,
      },
      {
        baseURL: BFF_API,
      }
    );

    return result;
  },
  chargebackAttachFile: async ({ id, file }) => {
    const result = await axios.post(
      `chargebacks/${id}/attachments`,
      [{ fileName: file.fileName, url: file.url }],
      {
        baseURL: BFF_API,
      }
    );

    return result;
  },
  getWebhooks: async ({ page = 1, pageSize = DEFAULT_PAGE_SIZE, filters }) => {
    const { data, headers } = await axios.get("webhooks", {
      baseURL: BFF_API,
      params: {
        Page: page,
        PageSize: pageSize,
        ReferenceId: filters?.referenceId,
      },
    });
    const pageNumber = parseInt(headers["x-current-page"]);
    const pgSize = parseInt(headers["x-page-size"]);
    const totalAvailable = parseInt(headers["x-total-item-count"]);

    return {
      data: data?.data || [],
      pagination: {
        pageNumber,
        pageSize: pgSize || DEFAULT_PAGE_SIZE,
        totalAvailable,
      },
    };
  },
  getWebhookAttempts: async (webhookId) => {
    const res = await axios.get(`${BFF_API}/webhooks/${webhookId}/attempts`);

    return res;
  },
  resendWebhook: async (webhookId) => {
    const res = await axios.post(`${BFF_API}/webhooks/${webhookId}/resend`);

    return res;
  },
  getCardSchemes: async () => {
    const { data } = await axios.get(`brands/card-schemes`, {
      baseURL: BFF_API,
    });

    return (data?.data || []).map(({ id, brandCode }) => ({
      value: id,
      label: brandCode,
      id,
    }));
  },
  getAcquirers: async () => {
    const { data } = await axios.get(`acquirers`, {
      baseURL: BFF_API,
    });

    return (data?.data || []).map(({ id, name }) => ({
      value: id,
      label: name,
      id,
    }));
  },
  changePassword: async (personId, newPassword) => {
    const result = await axios.post(
      `merchants/terminal-password`,
      { personId, newPassword },
      { baseURL: BFF_API }
    );
    return result;
  },
  getPayouts: async (params) => {
    const { data, headers } = await axios.get(`settlements`, {
      baseURL: BFF_API,
      params,
    });

    const page = parseInt(headers["x-current-page"]) || 1;
    const pageSize = parseInt(headers["x-page-size"]) || 10;
    const totalAvailable = parseInt(headers["x-total-item-count"]) || 0;

    return {
      data: data?.data || [],
      pagination: { page, pageSize, totalAvailable },
    };
  },
  getPayout: async ({ settlementId, ...params }) => {
    const { data, headers } = await axios.get(`settlements/${settlementId}`, {
      baseURL: BFF_API,
      params,
    });

    const page = parseInt(headers["x-current-page"]) || 1;
    const pageSize = parseInt(headers["x-page-size"]) || 10;
    const totalAvailable = parseInt(headers["x-total-item-count"]) || 0;

    return {
      data: data?.data || [],
      pagination: { page, pageSize, totalAvailable },
    };
  },
};

export default api;
