import React, { useState, Fragment, useContext } from "react";
import { BananaContext } from "@wikimedia/react.i18n";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";

import { useAuth } from '@context/Auth0Context';
import Alert from "@assets/icons/Alert";
import { useStyles } from "./style";

export function Export({ filters, reportType, onClose, onDownload }) {
  const translator = useContext(BananaContext);
  const [exporting, setExporting] = useState(false);
  const {
    user: { email },
  } = useAuth();

  const [exportType, setExportType] = useState("xlsx");
  const classes = useStyles();

  const options = [
    {
      value: "xlsx",
      label: "Xlsx",
    },
    {
      value: "csv",
      label: "Csv",
    },
  ];

  const handleExport = async () => {
    try {
      setExporting(true);
      await onDownload({ exportType, ...filters }, reportType);
      onClose();
    } catch (error) {
      setExporting(false);
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Box m={1}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel disabled component="legend">
            {translator.i18n("choose-file-type")}
          </FormLabel>
          <RadioGroup
            className={classes.options}
            row
            aria-label="position"
            name="position"
            value={exportType}
            onChange={(event) => setExportType(event.target.value)}
          >
            {options.map(({ value, label }) => (
              <FormControlLabel
                key={value}
                value={value}
                labelPlacement="start"
                control={<Radio color="primary" />}
                label={label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <Paper elevation={1} className={classes.subtitle}>
          <Typography variant="h5" className={classes.alert}>
            <Alert />
            {translator.i18n("export-details-alert")}
          </Typography>
          <p>{translator.i18n("export-details-help", email)}</p>
        </Paper>
      </Box>
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        className={classes.actions}
      >
        <Grid item>
          <Button disabled={exporting} variant="outlined" onClick={onClose}>
            {translator.i18n("cancel")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={exporting}
            variant="contained"
            onClick={handleExport}
          >
            {translator.i18n("export", "")}
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Export;
