import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function KlarnaIcon(props) {
  return (
    <SvgIcon {...props} viewBox="-4 -15 50 75" style={{ width: 40, height: 40, backgroundColor: "#FEA7CC", borderRadius: "100%" }} fill="none">
      <path        
        d="m4.69343,42.35377l9.90054,0l0,-19.67828l16.3036,19.67828l11.8376,0l-15.38888,-19.12325l1.07615,-1.00914c0.26904,-0.20183 0.69949,-0.50457 1.07615,-0.85777c2.36752,-1.91737 4.14316,-4.03657 5.75738,-6.60988c2.42133,-3.8852 3.33605,-7.92177 3.60509,-12.91702l-9.47008,0c-0.69949,9.08228 -6.72591,15.89399 -14.797,19.98102l0,-19.98102l-9.79292,0l-0.10761,40.46661l0,0.05046z"
        fill="#0B051D"
      />
    </SvgIcon>
  );
}

export default KlarnaIcon;