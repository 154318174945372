import React, { Suspense, memo } from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from '@context/Auth0Context';

import { useUser } from '@hooks/useUser';

import Loading from "@components/molecules/Loading";
import PageErrorBoundary from '@components/templates/PageErrorBoundary';

export const ProtectedRoute = memo(({ component, permissions, ...args }) => {
	const { userHasScope } = useUser();

	const renderRoute = () => (
		<Route
			component={withAuthenticationRequired(component)}
			{...args}
		/>
	);

	return (
		<PageErrorBoundary>
			<Suspense fallback={<Loading />}>
				{
					permissions
						? userHasScope(permissions) && renderRoute()
						: renderRoute()
				}
			</Suspense>
		</PageErrorBoundary>
	);
}, (prevProps, nextProps) => prevProps.path === nextProps.path);

export default ProtectedRoute;