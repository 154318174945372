import React from "react";

import Box from "@mui/material/Box";

import Logo from "@legacy/components/logo";
import LogoIcon from "@legacy/components/logoIcon";

export function PaybyrdLogo({ open = true, ...props }) {
  return (
    <Box {...props} sx={{ paddingTop: "12px" }}>
      {open ? <Logo id={props.id} /> : <LogoIcon id={props.id} />}
    </Box>
  );
}

export default PaybyrdLogo;
