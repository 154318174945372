import { createSlice } from '@reduxjs/toolkit';
import { createSagaAction } from 'saga-toolkit';

import { DEFAULT_PAGE_SIZE } from "@services/config";

const name = "stores";

export const actions = {
	FETCH: `${name}/fetchStores`,
	SET_PASSWORD: `${name}/setPassword`,
}

export const fetchStores = createSagaAction(actions.FETCH);
export const fetchSetPassword = createSagaAction(actions.SET_PASSWORD);

export const storesSlice = createSlice({
	name,
	initialState: {
		loading: false,
		error: null,
		success: null,
		data: null,
		pagination: {
			pageNumber: 1, 
			pageSize: DEFAULT_PAGE_SIZE, 
			rowCount: 0
    },
	},
	reducers: {
	},
	extraReducers: {
		[fetchStores.pending]: (state) => ({ ...state, loading: true }),
		[fetchStores.fulfilled]: (state, { payload: { data, pagination }}) => ({ ...state, loading: false, success: true, data, pagination }),
		[fetchStores.rejected]: (state) => ({ ...state, loading: false, error: true }),
		[fetchSetPassword.pending]: (state) => ({ ...state, loading: true }),
		[fetchSetPassword.fulfilled]: (state) => ({ ...state, loading: false, success: true }),
		[fetchSetPassword.rejected]: (state) => ({ ...state, loading: false, error: true }),
	}
});

export default storesSlice.reducer;