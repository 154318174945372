import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function DankortIcon(props) {
	return (
		<SvgIcon {...props} viewBox="1160 -310 40 2210" style={{ width:40, height:40 }}>
            <g transform="matrix(4.16667,0,0,4.16667,0,0)"><path d="M368.5,38.742C332.132,38.742 215.895,38.742 179.527,38.742C101.239,38.742 37.782,102.199 37.782,180.468C37.782,258.764 101.239,322.206 179.527,322.206C215.895,322.206 332.132,322.206 368.5,322.206C446.768,322.206 510.225,258.764 510.225,180.468C510.225,102.199 446.768,38.742 368.5,38.742Z" style={{ fill:'white', fillRule:'nonzero' }}/><path d="M401.533,171.9L464.372,246.633C477.386,227.825 485.03,205.025 485.03,180.468C485.03,154.549 476.514,130.578 462.145,111.203L401.533,171.9Z" style={{ fill: 'rgb(222,0,0)' }} /><path d="M223.466,104.895C270.394,104.895 305.751,116.835 309.391,156.373L358.779,104.888L457.12,104.888C435.726,79.85 403.935,63.944 368.5,63.944L179.527,63.944C144.078,63.944 112.281,79.85 90.887,104.895C122.19,104.895 194.892,104.895 223.466,104.895Z" style={{ fill: 'rgb(222,0,0)' }} /><path d="M146.148,154.738L129.39,200.813C129.39,200.813 179,200.813 197.001,200.813C216.988,200.813 223.037,192.538 227.509,176.607C231.937,160.852 220.816,154.738 207.158,154.738C195.692,154.738 146.148,154.738 146.148,154.738Z" style={{ fill: 'rgb(222,0,0)' }} /><path d="M359.189,252.923L306.956,186.913C297.294,231.92 265.68,252.916 206.415,252.916C161.93,252.916 109.937,252.916 88.302,252.916C109.67,279.765 142.62,297.011 179.527,297.011L368.5,297.011C405.394,297.011 438.336,279.772 459.704,252.923L359.189,252.923Z" style={{ fill: 'rgb(222,0,0)' }} /></g>
		</SvgIcon>
	);
}

export default DankortIcon;
