import { createSlice } from '@reduxjs/toolkit';
import { createSagaAction } from 'saga-toolkit';

const name = "dashboard";

export const actions = {
	FETCH: `${name}/fetchDashboard`,
}

export const fetchDashboard = createSagaAction(actions.FETCH);

export const dashboardSlice = createSlice({
	name,
	initialState: {
		loading: true,
		error: null,
		success: null,
		transactions: null,
		totals: null,
		revenue: null,
	},
	reducers: {
		setTransactions: (state, { payload: data }) => ({ ...state, transactions: data, loading: false }),
		clear: (state) => ({ ...state, error: null, success: null }),
	},
	extraReducers: {
		[fetchDashboard.pending]: (state) => ({ ...state, loading: true }),
		[fetchDashboard.fulfilled]: (state) => ({ ...state, loading: false, success: true }),
		[fetchDashboard.rejected]: (state) => ({ ...state, loading: false, error: true }),
	}
});

export const { clear, setTransactions } = dashboardSlice.actions;
export default dashboardSlice.reducer;