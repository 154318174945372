import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function Chevron(props) {
	return (
		<SvgIcon {...props}>
			<path d="M12.267 19.2c-0.533 0-0.8-0.267-1.067-0.533l-10.667-10.667c-0.533-0.533-0.533-1.6 0-2.133s1.6-0.533 2.133 0l9.6 9.6 9.6-9.6c0.533-0.533 1.6-0.533 2.133 0s0.8 1.6 0 2.133l-10.667 10.667c-0.267 0.267-0.533 0.533-1.067 0.533z"></path>
		</SvgIcon>
	);
}

export default Chevron;