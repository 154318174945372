import React, { useMemo, useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import { init as initApm } from '@elastic/apm-rum';
import { IntlProvider } from '@wikimedia/react.i18n';
import useHotjar from 'react-use-hotjar';
import TagManager from 'react-gtm-module';
import Intercom from '@intercom/messenger-js-sdk';

import { useAuth } from '@context/Auth0Context';
import { makeTheme } from '@styles/theme';
import { Root } from 'root';
import messages from './legacy/i18n';

import Loading from '@components/molecules/Loading';

import { NODE_ENV, APP_INTERCOM_API_KEY } from '@services/config';
import { storeLocale, refreshTimeZoneSpecs, capitalizeFirstLetter } from '@utils';
import { StyledEngineProvider } from '@mui/material/styles';

// Configure elastic search API
initApm({
  serviceName: 'paybyrd-dashboard',
  serverUrl: 'https://17eaa08527544fc8a5195f47a30ead3f.apm.westeurope.azure.elastic-cloud.com:443',
  environment: process.env.NODE_ENV,
});

export function App() {
  const { mode, locale } = useSelector((state) => state.ui);
  const theme = useMemo(() => makeTheme(mode, locale), [mode, locale]);
  const { initHotjar } = useHotjar();
  const { isLoading, user, isAuthenticated } = useAuth();
  const isDev = NODE_ENV === 'development';
  // TODO - Is it possible to receive this the same as API_URL?
  const isStage = window.location.href.includes('dashboard-s');

  useEffect(() => {
    // Google Tag Manager just for Prod
    if (!isStage && !isDev) {
      TagManager.initialize({ gtmId: 'GTM-K7MRC7M' });
    }
    refreshTimeZoneSpecs();
  }, []);

  useEffect(() => {
    if (isStage || isDev) {
      initHotjar(2699757, 6);
    } else {
      // Production
      initHotjar(2739668, 6);
    }
  }, [initHotjar]);

  useEffect(() => {
    if (!user || !isAuthenticated) {
      return;
    }

    const [firstName, lastName] = (user.nickname || '').split('.');

    Intercom({
      app_id: APP_INTERCOM_API_KEY,
      user_id: user.email,
      name: !firstName ? user.email : `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`,
      email: user.email,
      region: 'eu',
    });
  }, [user, isAuthenticated]);

  if (isLoading) {
    return <Loading />;
  }

  storeLocale(locale);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <IntlProvider locale={locale} messages={messages}>
            <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
              <ConfirmProvider defaultOptions={{ confirmationButtonProps: { autoFocus: true } }}>
                <StyledEngineProvider injectFirst>
                  <CssBaseline />
                  <Root />
                </StyledEngineProvider>
              </ConfirmProvider>
            </SnackbarProvider>
          </IntlProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
}

export default App;
