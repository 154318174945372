import { fork, call } from "redux-saga/effects";
import { takeEveryAsync } from "saga-toolkit";
import api from "../../../api";

import { fetchWebhooks } from "./reducer";

function* handleFetchWebhooks({ meta: { arg } }) {
  try {
    const data = yield call(api.getWebhooks, arg);
    return data.value ? data.value : data;
  } catch (error) {
    throw new Error(error.message);
  }
}

function* getWebhooks() {
  yield takeEveryAsync(fetchWebhooks.type, handleFetchWebhooks);
}

export function* webhooksSaga() {
  yield fork(getWebhooks);
}
