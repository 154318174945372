import { createSlice } from '@reduxjs/toolkit';
import { createSagaAction } from 'saga-toolkit';

const name = "administration";

export const actions = {
	FETCH: `${name}/fetchReports`,
}

export const fetchReports = createSagaAction(actions.FETCH);

export const administrationSlice = createSlice({
	name,
	initialState: {
		loading: true,
		error: null,
		success: null,
		reports: null,
	},
	reducers: {
		clear: (state) => ({ ...state, error: null, success: null }),
	},
	extraReducers: {
		[fetchReports.pending]: (state) => ({ ...state, reports: null, loading: true }),
		[fetchReports.fulfilled]: (state, { payload: reports}) => ({ ...state, loading: false, success: true, reports }),
		[fetchReports.rejected]: (state) => ({ ...state, loading: false, error: true }),
	}
});

export const { clear } = administrationSlice.actions;
export default administrationSlice.reducer;