import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default class ErrorNotFound extends Component {

	render() {
		return (
			<Box style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
				<Typography variant="h1">Oops! That page can’t be found.</Typography>
				<Typography variant="p">It looks like nothing was found at this location. Maybe try one of the links in the menu or press back to go to the previous page.</Typography>
			</Box>
		);
	}
}