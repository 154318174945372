import { createSlice } from "@reduxjs/toolkit";
import { createSagaAction } from "saga-toolkit";

import { DEFAULT_PAGE_SIZE, TRANSACTION_SOURCES } from "@services/config";

import { getPeriod } from "@utils";

const name = "orders";

export const actions = {
  FETCH: `${name}/fetch`,
};

export const fetch = createSagaAction(actions.FETCH);

export const transactionsSlice = createSlice({
  name,
  initialState: {
    loading: true,
    data: [],
    error: null,
    success: null,
    pagination: {
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      returned: 0,
      total: 0,
      totalAvailable: 0,
    },
    filters: {
      channel: [TRANSACTION_SOURCES.E_COMMERCE, TRANSACTION_SOURCES.POS, TRANSACTION_SOURCES.PAYBYLINK],
      period: getPeriod(),
      query: "",
      storeId: [],
    },
  },
  reducers: {
    setFilters: (state, { payload }) => ({ ...state, filters: payload }),
    clear: (state) => ({ ...state, error: null, success: null }),
    setSuccess: (state, { payload }) => ({ ...state, success: payload }),
  },
  extraReducers: {
    [fetch.pending]: (state) => {
      if (state.loading === false) {
        state.loading = true;
      }
    },
    [fetch.fulfilled]: (state, { payload: { data, pagination } }) => ({
      ...state,
      loading: false,
      data: data.map((transaction) => ({
        ...transaction,
      })),
      pagination,
    }),
    [fetch.rejected]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [fetch.rejected]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
  },
});

export const { setFilters, clear, setSuccess } = transactionsSlice.actions;
export default transactionsSlice.reducer;
