import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function AdministrationIcon(props) {
	return (
		<SvgIcon {...props} viewBox="9 0 1 19">
            <path fill="currentColor" fillRule="evenodd" d="M18.933 11.346l-.346 1.279a2.007 2.007 0 0 1-2.117 1.481l-.982-.086a7.576 7.576 0 0 1-.704.784 7.715 7.715 0 0 1-.777.678l.086.982a2.008 2.008 0 0 1-1.484 2.117l-1.279.346a2.007 2.007 0 0 1-2.341-1.085l-.416-.895a7.42 7.42 0 0 1-1.97-.554l-.81.567a2.009 2.009 0 0 1-2.574-.224l-.96-.96a2.009 2.009 0 0 1-.224-2.575l.566-.809a7.462 7.462 0 0 1-.531-1.99l-.895-.416A2.01 2.01 0 0 1 .081 7.641l.345-1.279a2.009 2.009 0 0 1 2.118-1.481l.982.086a7.539 7.539 0 0 1 1.455-1.455l-.086-.96A2.009 2.009 0 0 1 6.388.418l1.28-.345a2.008 2.008 0 0 1 2.344 1.094l.416.895a7.455 7.455 0 0 1 1.989.531l.8-.579a2.01 2.01 0 0 1 2.575.224l.959.96c.69.69.785 1.776.224 2.575l-.566.809c.269.636.447 1.305.531 1.99l.896.416a2.01 2.01 0 0 1 1.097 2.358zm-1.763-.915l-1.311-.611a.799.799 0 0 1-.458-.675 5.847 5.847 0 0 0-.614-2.291.796.796 0 0 1 .061-.812l.838-1.174a.42.42 0 0 0-.048-.538l-.959-.959a.42.42 0 0 0-.538-.048l-1.183.828a.796.796 0 0 1-.816.067 5.877 5.877 0 0 0-2.29-.614.796.796 0 0 1-.675-.457l-.611-1.312a.42.42 0 0 0-.489-.227l-1.279.346a.418.418 0 0 0-.32.441l.128 1.436a.797.797 0 0 1-.358.746 5.956 5.956 0 0 0-1.68 1.679.793.793 0 0 1-.732.355L2.4 6.483a.42.42 0 0 0-.442.32l-.345 1.28a.42.42 0 0 0 .227.489l1.312.611c.264.124.44.383.457.675a5.86 5.86 0 0 0 .614 2.29.798.798 0 0 1-.061.803l-.828 1.184a.42.42 0 0 0 .035.525l.96.959c.143.145.37.165.537.048l1.174-.828a.796.796 0 0 1 .812-.061 5.87 5.87 0 0 0 2.303.611.797.797 0 0 1 .675.457l.611 1.312c.086.185.292.28.489.227l1.28-.345a.42.42 0 0 0 .319-.442l-.128-1.436a.8.8 0 0 1 .355-.733 5.956 5.956 0 0 0 1.68-1.679.793.793 0 0 1 .732-.355l1.436.112a.419.419 0 0 0 .441-.32l.346-1.28.016.013a.418.418 0 0 0-.237-.489zm-7.666 2.492a3.426 3.426 0 0 1 0-6.852 3.426 3.426 0 1 1 0 6.852zm1.826-3.436a1.837 1.837 0 1 0 0 .01v-.01z" />
		</SvgIcon>
	);
}

export default AdministrationIcon;