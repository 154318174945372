import { createSlice } from '@reduxjs/toolkit';
import { createSagaAction } from 'saga-toolkit';

const name = "report";

export const actions = {
	FETCH: `${name}/fetchReport`,
  FETCHV1: `${name}/fetchReportV1` ,
}

export const fetchReport = createSagaAction(actions.FETCH);
export const fetchReportV1 = createSagaAction(actions.FETCHV1);

const initialState = {
	loading: false,
	count: 0,
	volume: 0,
	error: null,
	success: null,
	startDate: null,
	endDate: null,
	baseCurrency: null,
};

export const reportSlice = createSlice({
	name,
	initialState,
	reducers: {
		reset: (state) => (initialState)
	},
	extraReducers: {
		[fetchReport.pending]: (state) => ({ ...state }),
		[fetchReport.fulfilled]: (state, { payload: { count, volume, startDate, endDate, baseCurrency }}) => ({ ...state, count: count || 0, volume: volume || 0, startDate, endDate, baseCurrency }),
		[fetchReport.rejected]: (state, { error }) => ({ ...state, error }),
    [fetchReportV1.pending]: (state) => ({ ...state }),
		[fetchReportV1.fulfilled]: (state, { payload: { count, volume, startDate, endDate, baseCurrency }}) => ({ ...state, count: count || 0, volume: volume || 0, startDate, endDate, baseCurrency }),
		[fetchReportV1.rejected]: (state, { error }) => ({ ...state, error }),

	}
});

export const { reset } = reportSlice.actions;

export default reportSlice.reducer;
