import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	accessToken: null,
	secondaryAccessToken: null,
	merchants: [],
	scopes: [],
	appReady: false,
	phase: null,
	onboardingId: null,
	hasToLogout: null,
	onboardingUrl: null,
	currencies: [],
	channels: [],
	activityBranches: [],
};

export const actions = {
	GET_USER_INFO: "GET_USER_INFO",
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setAccessToken: (
			state,
			{
				payload: {
					accessToken,
					merchants,
					scopes,
					phase,
					onboardingId,
					hasToLogout,
					onboardingUrl,
					currencies,
					paymentMethods,
					groups,
					stores,
					countries,
					channels,
					plMerchants,
					activityBranches,
				},
			}
		) => ({
			...state,
			accessToken,
			merchants,
			scopes,
			phase,
			onboardingId,
			hasToLogout,
			onboardingUrl,
			currencies,
			paymentMethods,
			groups,
			stores,
			countries,
			plMerchants,
			channels,
			activityBranches,
		}),
		setAppReady: (state) => ({ ...state, appReady: true }),
	},
});

export const { setAccessToken, setAppReady } = userSlice.actions;

export default userSlice.reducer;
