import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export function AnalyticsIcon(props) {
	return (
		<SvgIcon {...props}>
			<g>
				<rect width="24" height="24" fill="none"/>
				<path d="M19.21289,4.70654C16.1416,1.63525,11.64746,1.29346,9.85742,1.28906H9.85547a.90024.90024,0,0,0-.90039.8999V5.028a8.84578,8.84578,0,0,0-7.512,11.58361c.00324.011.00049.022.00422.0329.0061.01813.01873.03162.02587.04907A8.85641,8.85641,0,0,0,18.6712,14.74414h3.42841A.90065.90065,0,0,0,23,13.84375,13.19331,13.19331,0,0,0,19.21289,4.70654ZM9.85547,16.76465a2.92163,2.92163,0,1,1,2.92187-2.9209A2.92472,2.92472,0,0,1,9.85547,16.76465ZM8.95508,6.83936V9.20752a4.73023,4.73023,0,0,0-3.82227,4.63623,4.63875,4.63875,0,0,0,.05915.58453l-2.26423.76581A7.04907,7.04907,0,0,1,8.95508,6.83936Zm-5.45459,10.061,2.25329-.76215a4.65617,4.65617,0,0,0,6.27692,1.88068l1.71051,1.7099A7.03376,7.03376,0,0,1,3.50049,16.90033ZM15.10834,18.549l-1.65412-1.6535a4.70447,4.70447,0,0,0,1.037-2.15131h2.36817A7.03018,7.03018,0,0,1,15.10834,18.549Zm2.70807-5.6051H14.48712a4.729,4.729,0,0,0-3.73126-3.7312v-6.094A11.26106,11.26106,0,0,1,17.93945,5.979a11.5753,11.5753,0,0,1,3.21973,6.96485Z" fill="#83858f"/>
			</g>
		</SvgIcon>
	);
}

export default AnalyticsIcon;