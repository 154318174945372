import React, { Fragment, useContext, useEffect, useState } from "react";
import { BananaContext } from "@wikimedia/react.i18n";
import Box from "@mui/material/Box";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/lab/Alert";

import { useStyles } from "./style";
import { api } from "api";
import { Typography } from "@mui/material";
import { formatDateTimezone } from "@utils";
import CopyIcon from "@assets/icons/CopyIcon";

export function WebhookDetail(webhookData) {
  const [copied, setCopied] = useState(null);
  const translator = useContext(BananaContext);
  const classes = useStyles();
  const [attempts, setAttempts] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await api.getWebhookAttempts(webhookData.data.id);

      setAttempts(data?.data);
    };

    fetch();
  }, []);

  if (!attempts) {
    return (
      <Box
        style={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography>{translator.i18n("loading-webhook-attempts")}</Typography>
      </Box>
    );
  }

  console.log(attempts);

  return (
    <Fragment>
      <Box>
        {attempts.map((attempt, key) => (
          <Fragment key={key}>
            <Box className={classes.header}>
              <span>
                #{attempts.length - key} {translator.i18n("attempt")}
              </span>
            </Box>
            <Box component="dl" className={classes.definitions}>
              <Box className={classes.definition}>
                <Box component="dt" className={classes.title}>
                  {translator.i18n("id")}
                </Box>
                <Box component="dd" className={classes.description}>
                  {attempt.id || "-"}
                </Box>
              </Box>
              <Box className={classes.definition}>
                <Box component="dt" className={classes.title}>
                  {translator.i18n("created-date")}
                </Box>
                <Box component="dd" className={classes.description}>
                  {formatDateTimezone(attempt.createdAt) || "-"}
                </Box>
              </Box>
              <Box className={classes.definition}>
                <Box component="dt" className={classes.title}>
                  {translator.i18n("url")}
                </Box>
                <Box component="dd" className={classes.description} title={attempt.settings?.url || ""}>
                  {attempt.settings?.url || "-"}
                </Box>
              </Box>
              <Box className={classes.definition}>
                <Box component="dt" className={classes.title}>
                  {translator.i18n("content")}
                </Box>
                <Box component="dd" className={classes.description}>
                  <CopyToClipboard
                    text={JSON.stringify(attempt.content || "{}", undefined, 4)}
                    onCopy={() => setCopied("JSON")}
                  >
                    <div>
                      <pre title={JSON.stringify(attempt.content || "{}", undefined, 4)}>
                        {JSON.stringify(attempt.content || "{}")}
                      </pre>
                      <CopyIcon style={{ cursor: "pointer" }} />
                    </div>
                  </CopyToClipboard>
                </Box>
              </Box>
              <Box className={classes.definition}>
                <Box component="dt" className={classes.title}>
                  {translator.i18n("status")}
                </Box>
                <Box component="dd" className={classes.description}>
                  {attempt.response?.statusCode || "-"}
                </Box>
              </Box>
              <Box className={classes.definition}>
                <Box component="dt" className={classes.title}>
                  {translator.i18n("response")}
                </Box>
                <Box component="dd" className={classes.description}>
                  <CopyToClipboard
                    text={JSON.stringify(attempt.response?.content || "{}", undefined, 4)}
                    onCopy={() => setCopied("JSON")}
                  >
                    <div>
                      <pre title={JSON.stringify(attempt.response?.content || "{}", undefined, 4)}>
                        {JSON.stringify(attempt.response?.content)}
                      </pre>
                      <CopyIcon style={{ cursor: "pointer" }} />
                    </div>
                  </CopyToClipboard>
                </Box>
              </Box>
            </Box>
          </Fragment>
        ))}
      </Box>
      <Snackbar open={!!copied} autoHideDuration={6000} onClose={() => setCopied(null)}>
        <Alert severity="info" color="info">
          {translator.i18n("copied-link", copied)}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}

export default WebhookDetail;
