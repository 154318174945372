import React, { Fragment } from "react";
import { useRecoilState } from "recoil";
import Modal from "@components/atoms/Modal";

import RefundModal from "./modals/refund";
import CaptureModal from "./modals/capture";
import DetailModal from "./modals/detail";
import WebhookDetailModal from "./modals/webhookDetail";
import ShiftDetailModal from "./modals/shiftDetail";
import OnboardingDetail from "./modals/onboardingDetail";
import CreatedSocialPayment from "./modals/createdSocialPayment";
import SocialPaymentDetail from "./modals/socialPaymentDetail";
import ExportReportModal from "./modals/export";
import ChangeStorePassword from "./modals/storePassword";

const modalComponentLookupTable = {
  RefundModal,
  CaptureModal,
  DetailModal,
  WebhookDetailModal,
  ShiftDetailModal,
  OnboardingDetail,
  CreatedSocialPayment,
  SocialPaymentDetail,
  ExportReportModal,
  ChangeStorePassword
};


import modalAtom from "./state";

export const ModalManager = () => {
  const [currentModals, setCurrentModals] = useRecoilState(modalAtom);

  const onClose = (type) => setCurrentModals({ ...currentModals, modals: [...currentModals.modals.filter((modal) => modal.type !== type)] });

  const renderModals = () => {
    return currentModals
      .modals
      .map((modal, index) => {
        if (modal) {
          const { type, props = {}, title } = modal;
          const View = modalComponentLookupTable[type];

          return (
            <Modal
              title={title}
              open={true}
              onClose={() => onClose(type)}
              close
              key={type + index}
              fullscreen={props.fullscreen}
            >
              <View {...props} onClose={() => onClose(type)} />
            </Modal>
          );
        }

        return null;
      })
      .filter((x) => x);
  };

  return <Fragment>{renderModals()}</Fragment>;
};

export default ModalManager;
