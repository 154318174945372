import { selector } from 'recoil';
import modalAtom from "..";

const modalSelector = selector({
  key: 'ModalSelector',
  get: ({ get, getCallback }) => {
    const currentModals = get(modalAtom);

    const openModal = getCallback(({ set }) => async ({ type, title, props }) => {
      set(modalAtom,
        {
          ...currentModals,
          modals: [
            ...currentModals.modals,
            {
              type,
              title,
              props
            }]
        });
    });

    return openModal;
  },
});

export default modalSelector;