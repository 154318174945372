import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    color: "#955AF7",
    fontSize: 18,
    fontWeight: 600,

    "& > span": {
      paddingBottom: 10,
      borderBottom: "3px solid #955AF7",

      "&:after": {
        content: '""',
        display: "block",
        width: "100%",
        height: "1px",
        background: "#2A2A2A",
        marginTop: "10px",
      },
    },
  },
  definitions: {
    marginBottom: 20,
    display: "flex",
    flexWrap: "wrap",
  },
  definition: {
    padding: "20px 0",
    fontSize: 16,
    width: "25%",
  },
  title: {
    textTransform: "uppercase",
    color: theme.palette.mediumGray.main,
    fontSize: 12,
    marginBottom: 4,
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "right",
  },
  description: {
    margin: 0,
    paddingRight: 10,
    textOverflow: "ellipsis",
    overflow: "hidden",

    "& > div": {
      display: "flex",

      "& > pre": {
        margin: 0,
        marginRight: 10,
        maxWidth: "100%",
        overflow: "hidden",
      },
    },
  },
}));
