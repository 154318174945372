import React, { useState, Fragment, useContext } from "react";
import { BananaContext } from "@wikimedia/react.i18n";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import NumberInput from "@legacy/components/numberInput";
import FormTextInput from "@legacy/components/formTextInput";

import { currencyFormat } from "@utils";

import { useCapture } from "./useCapture";

export function Capture({ transaction, onClose }) {
  const translator = useContext(BananaContext);
  //const [transaction] = useSelector(state => state.transactions.data.filter(transaction => transaction.paymentId === paymentId));
  const [amount, setAmount] = useState(transaction.amount || 0);
  const { capture, loading } = useCapture();
  const { paymentId, merchantId, currency, precision = 2 } = transaction;

  const handleCapture = async () => {
    if (!hasError()) {
      try {
        await capture(paymentId, amount, merchantId);
        onClose();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const hasError = () =>
    parseFloat(amount) > parseFloat(transaction ? transaction.amount : 0);

  if (transaction) {
    return (
      <Fragment>
        <FormTextInput
          inputComponent={NumberInput}
          style={{ marginTop: 32, marginBottom: 80 }}
          error={hasError()}
          label={translator.i18n("amount")}
          value={amount}
          precision={precision}
          onChange={(event) => setAmount(event.target.value)}
          helperText={
            <Fragment>
              <Typography variant="body2">
                {translator.i18n("order-amount")}
              </Typography>
              <Typography
                variant="body2"
                style={{ marginLeft: 5, fontWeight: 700 }}
              >
                {currencyFormat(
                  transaction.amount,
                  undefined,
                  currency,
                  precision
                )}
              </Typography>
            </Fragment>
          }
        />
        <Grid container direction="row" spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button disabled={loading} variant="outlined" onClick={onClose}>
              {translator.i18n("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={hasError() || loading}
              variant="contained"
              onClick={handleCapture}
            >
              {loading
                ? translator.i18n("capturing")
                : translator.i18n("capture")}
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  return null;
}

export default Capture;
