import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { BananaContext } from "@wikimedia/react.i18n";

import { fetchSetPassword } from "@features/settings/Store/reducer";

export function useChangePassword() {
  const [loading, setLoading] = useState(false);
  const translator = useContext(BananaContext);
  const dispatch = useDispatch();

  const changePassword = async (personId, password) => {
    setLoading(true);
    try {
      const { error } = await dispatch(
        fetchSetPassword({ personId, password, translator })
      );

      if (error) {
        throw error;
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, changePassword };
}
