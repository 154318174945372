import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import queryString from "query-string";
import "core-js/stable";
import "regenerator-runtime/runtime";

import { Auth0ProviderWithHistory } from '@context/Auth0Context';
import store from "@store";
import App from "./App";
import { RecoilRoot } from "recoil";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <RecoilRoot>
    <HashRouter>
      <QueryParamProvider
        adapter={ReactRouter5Adapter}
        options={{
          searchStringToObject: queryString.parse,
          objectToSearchString: queryString.stringify,
        }}
      >
        <Auth0ProviderWithHistory>
          <Provider store={store}>
            <App />
          </Provider>
        </Auth0ProviderWithHistory>
      </QueryParamProvider>
    </HashRouter>
  </RecoilRoot>
);