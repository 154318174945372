import { call } from "redux-saga/effects";
import { takeLatestAsync } from "saga-toolkit";
import omit from "lodash.omit"
import isEmpty from 'lodash/isEmpty';

import { getPeriod, getParamsFromFilters as getParamsFromFiltersV1 } from "@utils";
import { api } from "api";
import { fetchReport, fetchReportV1 } from "./reducer";
import { getParamsFromFilters } from "../../transactions/saga";
import Cookies from "js-cookie";

function* handleReport({ meta: { arg } }) {
	try {
		const [hash, params] = window.location.hash.split("#")[1].split("?");
		const paramsObj = Object.fromEntries(new URLSearchParams(params));
		const filters = arg ? getParamsFromFilters({ ...arg, shiftId: paramsObj.shiftId }) : getPeriod();

		const data = yield call(api.getTransactionsBalanceTotalizators, omit({
			...filters,
			Currency: filters.Currency,
		}, ["Page", "PageSize"]));
		let volume = 0;
		let baseCurrency = null;

		if (data?.volumes?.length === 1) {
			baseCurrency = data?.volumes[0].currency;
			volume = data?.volumes[0].volume;
		} else {
			baseCurrency = null;
		}

		return {
			startDate: arg ? filters.TransactionDateFrom : arg?.period?.startDate,
			endDate: arg ? filters.TransactionDateTo : arg?.period?.endDate,
			count: data?.transactionCount,
			volume,
			baseCurrency
		};
	} catch (error) {
		return error;
	}
}

function* handleReportV1({ meta: { arg } }) {
	try {
		const [hash, params] = window.location.hash.split("#")[1].split("?");
		const paramsObj = Object.fromEntries(new URLSearchParams(params));
		const { refetch, ...filter } = arg ?? {};
		const filters = filter && !isEmpty(filter) ? getParamsFromFiltersV1(filter) : getPeriod();
		const { startDate, endDate, merchantIds } = filters;
		const currency = Cookies.get("currentCurrency");

		const data = yield call(api.getBalanceReports, {
			interval: "daily",
			startDate,
			endDate,
			merchantIds,
			shiftId: paramsObj.shiftId,
			currency,
			Refetch: refetch
		});
		const totalCount = data.reduce(
			(acc, transaction) =>
				(transaction?.refundCount || 0) + 
				(transaction?.revenueCount || 0) + acc,
			0
		);
		const totalVolume = data.reduce(
			(acc, transaction) => (transaction?.revenue || 0) + acc,
			0
		);
		const totalDeductions = data.reduce(
			(acc, transaction) => (transaction?.deductions || 0) + acc,
			0
		);

		return {
			startDate,
			endDate,
			count: totalCount,
			volume: totalVolume + totalDeductions,
		};
	} catch (error) {
		return error;
	}
}

export function* reportSaga() {
	yield takeLatestAsync(fetchReport.type, handleReport);
	yield takeLatestAsync(fetchReportV1.type, handleReportV1);
}
