import React, { Fragment, useState } from "react";
import clsx from "clsx";
import { useRecoilValue } from "recoil";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from '@mui/icons-material/Close';
import { Menu } from "@components/molecules/Menu";
import { navigationRoutesSelector } from "@recoil/selectors/navigationRoutes";
import { useStyles } from "./style";
import PaybyrdLogo from "@components/atoms/PaybyrdLogo";
import { useSelector } from "react-redux";

export function SidebarMenu({ offset }) {
  const classes = useStyles({ offset });
  const container = window !== undefined ? () => window.document.body : undefined;
  const [mobileMenuIsOpen, setMobileMenuIsOPen] = useState(false);

  const {
    topItems,
    bottomItems,
  } = useRecoilValue(navigationRoutesSelector);

  return (
    <Fragment>
      <Drawer
        sx={{ display: { xs: 'none', md: 'block' }, paddingTop: '0px' }}
        variant="permanent"
        className={clsx(classes.drawer, classes.drawerOpen)}
        classes={{ paper: clsx(classes.drawerPaper, classes.drawerOpen) }}
      >
        <Box className={clsx(classes.logoContainer)}>
          <PaybyrdLogo id="sidebar-logo" />
        </Box>
        <Menu
          offset={offset}
          topItems={topItems}
          bottomItems={bottomItems}
          onClose={() => setMobileMenuIsOPen(false)}
        />
      </Drawer>
      <AppBar sx={{ display: { md: 'none', xs: 'block' } }} position="sticky" className={classes.appBar}>
        <Toolbar classes={{ root: classes.toolbar, gutters: classes.toolbarGutters }}>
          <PaybyrdLogo id="logo-mobile" sx={{ marginTop: '15px' }} />
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={() => setMobileMenuIsOPen(!mobileMenuIsOpen)}
            className={classes.menuButton}
            size="large">
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={'top'}
        container={container}
        variant="temporary"
        open={mobileMenuIsOpen}
        onClose={() => setMobileMenuIsOPen(!mobileMenuIsOpen)}
        classes={{
          paper: clsx(classes.drawerPaper, classes.drawerPaperMobile),
        }}
        ModalProps={{ keepMounted: true }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px' }}>
          <PaybyrdLogo id="logo-mobile-expanded" />
          <IconButton
            onClick={() => setMobileMenuIsOPen(false)}
            size="large">
            <CloseIcon />
          </IconButton>
        </Box>
        <Menu
          offset={offset}
          topItems={topItems}
          bottomItems={bottomItems}
          onClose={() => setMobileMenuIsOPen(false)}
        />
      </Drawer>
    </Fragment>
  );
}

export default SidebarMenu;
